body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiInputLabel-formControl {
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 26px !important;
  color: #111729 !important;
  position:  relative !important;
  transform: none !important;
  margin-bottom: 4px !important;
}
.MuiInputBase-input{
  background-color: transparent !important;
  width: 100% !important;
  border-radius: 12px !important;
}
.MuiOutlinedInput-notchedOutline{
  border-color: #E0E3E7 !important
}
.payment-title{
  /* font-family: Plus Jakarta Sans; */
  /* font-size: 24px; */
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 8px;

}
.MuiCardHeader-root, .MuiCardContent-root{
  padding-left: 0px !important;
}
.MuiCardHeader-title {
  font-size: 16px !important;
  font-weight: 500 !important;
  text-transform: none;
}
.MuiCardHeader-subheader{
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #808080;
  text-transform: none;
}
.MuiCardHeader-avatar{
  /* border: 1px solid #E3E8EF; */
  padding: 8px;
  border-radius: 8px;
}
.MuiButtonBase-root, .MuiPaper-root {
  /* border-color: #677489 !important; */
  /* padding: 8px !important; */
  border-radius: 8px !important;
}
.MuiPaper-root{
  /* border: 1px solid #E3E8EF !important */
}
.MuiButton-containedPrimary{
  border-radius: 50px !important;

}